<template>
  <div>
    <PurchaseInvoicesProfileCard />
    <BaseDocumentationCard
      v-model="documentation"
      :resource="purchaseInvoice"
      :observations="observations"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
      add-link-text="+ Añadir documentación"
    />
    <PurchaseInvoicesBillingCard />
    <PurchaseInvoicesLinesTable />
    <b-card no-body>
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col cols="auto">
          <span class="text-uppercase font-weight-bold mb-0">
            albaranes asociados
          </span>
        </b-col>
        <b-col cols="auto">
          <b-link @click="handleAddDeliveryNotesButtonClick">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir albaran
            </b-button>
          </b-link>
        </b-col>
      </b-row>
      <DeliveryNotesTable
        ref="delivery-notes-table"
        :purchase-invoice-id="purchaseInvoiceId"
        :loading="loading"
        hide-toolbar
      />
    </b-card>
    <BaseCommentCard :filters="commentsFilters" />
  </div>
</template>

<script>
import DeliveryNotesApi from '@/api/delivery-notes-api'
import { mapActions, mapGetters } from 'vuex'
import PurchaseInvoicesLinesTable from '@/components/purchase-invoices/table/PurchaseInvoicesLinesTable.vue'
import PurchaseInvoicesBillingCard from '@/components/purchase-invoices/card/PurchaseInvoicesBillingCard.vue'
import PurchaseInvoicesProfileCard from '@/components/purchase-invoices/card/PurchaseInvoicesProfileCard.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import DeliveryNotesTable from '@/components/delivery-notes/table/DeliveryNotesTable.vue'
import BaseCommentCard from '@/components/ui/card/BaseCommentCard.vue'

export default {
  components: {
    BaseCommentCard,
    DeliveryNotesTable,
    BaseDocumentationCard,
    PurchaseInvoicesProfileCard,
    PurchaseInvoicesBillingCard,
    PurchaseInvoicesLinesTable,
  },
  data() {
    return {
      loading: false,
      purchaseInvoiceId: this.$route.params.id.toString(),
    }
  },
  computed: {
    ...mapGetters('purchaseInvoice', {
      purchaseInvoice: 'getPurchaseInvoice',
    }),
    commentsFilters() {
      return { purchase_invoice_id: this.$route.params.id }
    },
    observations() {
      return this.purchaseInvoice?.observations || 'Sin observaciones'
    },
    documentation: {
      get() {
        return this.purchaseInvoice?.documentation || []
      },
      set(value) {
        this.setPurchaseInvoiceDocumentation(value)
      },
    },
    deleteEndPoint() {
      return this.purchaseInvoice ? `/purchase-invoices/delete_doc/${this.purchaseInvoice.id}` : null
    },
    updateEndPoint() {
      return this.purchaseInvoice ? `/purchase-invoices/${this.purchaseInvoice.id}` : null
    },
  },
  mounted() {
    this.loadPurchaseInvoiceDetails()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    ...mapActions('purchaseInvoice', ['loadPurchaseInvoice', 'setPurchaseInvoiceDocumentation']),
    async loadPurchaseInvoiceDetails() {
      this.setLoading(true)
      try {
        await this.loadPurchaseInvoice(this.$route.params.id)
      } finally {
        this.setLoading(false)
      }
    },
    async handleAddDeliveryNotesButtonClick() {
      this.loading = true
      try {
        await DeliveryNotesApi.createFormPurchaseInvoice(this.$route.params.id)
        await this.$refs['delivery-notes-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
