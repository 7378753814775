<template>
  <BaseModal
    v-model="model"
    title="Creación de nuevo pago"
    size="lg"
    :loading="loading"
    :scrollable="false"
    @confirm-button-click="handleConfirmButtonClick"
  >
    <validation-observer ref="purchase-invoice-payment-creation-form">
      <b-form
        class="py-2"
        @submit.prevent
      >
        <b-row align-h="center">
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Fecha"
              rules="required"
            >
              <b-form-group
                label="Fecha"
                label-for="purchase-invoice-payment-date"
              >
                <b-form-datepicker
                  id="purchase-invoice-payment-date"
                  v-model="form.payment_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="$getValidationState(validationContext)"
                  placeholder="Fecha"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  :label-help="null"
                  boundary="viewport"
                  start-weekday="1"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Importe"
              :rules="'required|max_value:' + maxAmount"
            >
              <b-form-group
                label="Importe"
                label-for="purchase-invoice-payment-amount"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="purchase-invoice-payment-amount"
                    v-model="form.amount"
                    placeholder="Importe"
                    type="number"
                    :state="$getValidationState(validationContext)"
                  />
                  <b-input-group-append is-text>
                    €
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
              <BaseFileInput
                ref="payment-file-input"
                v-model="paymentFile"
                class="d-inline"
                :multiple="false"
              >
                <b-link class="text-indigo mr-1">
                  Adjuntar documento
                  <feather-icon
                    icon="UploadIcon"
                    size="19"
                  />
                </b-link>
              </BaseFileInput>
              <div
                v-if="paymentFile"
                class="pt-1"
              >
                {{ paymentFile.name }}
                <b-link>
                  <span
                    class="d-inline-block px-50 text-danger"
                    @click="handleDeleteDocumentLinkClick()"
                  >
                    <feather-icon
                      v-b-tooltip.hover 
                      title="Eliminar"
                      icon="TrashIcon"
                      size="18"
                    />
                  </span>
                </b-link>
              </div>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </BaseModal>
</template>

<script>
import { mapActions } from 'vuex'
import PurchaseInvoicePaymentsApi from '@/api/purchase-invoice-payments-api'
import DateTimeService from '@/shared/services/date-time-service'
import BaseModal from '@/components/ui/modal/BaseModal.vue'
import BaseFileInput from '@/components/ui/file/BaseFileInput.vue'

export default {
  name: 'PurchaseInvoicePaymentCreationModal',
  components: { BaseFileInput, BaseModal },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      maxAmount: null,
      form: {
        amount: null,
        payment_date: DateTimeService.today(),
      },
      paymentFile: null,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    model(value) {
      if (value) {
        this.loadRemainingPayment()
      }
    },
  },
  methods: {
    ...mapActions('purchaseInvoice', ['loadPurchaseInvoice']),
    async loadRemainingPayment() {
      if (this.form.amount || !this.purchaseInvoice) {
        return
      }

      this.loading = true
      try {
        const remainingAmount = await PurchaseInvoicePaymentsApi.getRemaining(this.purchaseInvoice.id)
        this.form.amount = remainingAmount
        this.maxAmount = remainingAmount
      } finally {
        this.loading = false
      }
    },
    async handleConfirmButtonClick() {
      const valid = await this.$refs['purchase-invoice-payment-creation-form'].validate()
      if (!valid || !this.purchaseInvoice) {
        return
      }

      this.loading = true
      try {
        await PurchaseInvoicePaymentsApi.create({ ...this.form, documentation: [this.paymentFile], purchase_invoice_id: this.purchaseInvoice.id })
        await this.loadPurchaseInvoice(this.purchaseInvoice.id)
        this.$emit('input', false)
        this.clearForm()
      } finally {
        this.loading = false
      }
    },
    handleDeleteDocumentLinkClick() {
      this.paymentFile = null
    },
    clearForm() {
      this.form = { amount: null, payment_date: null }
    },
  },
}
</script>

<style scoped>
</style>
