var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","spinner-variant":"primary","rounded":"sm"}},[_c('b-card-title',{staticClass:"m-0 py-1 px-2"},[_c('b-row',{attrs:{"align-h":"end","align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
              resource: _vm.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction:
                _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
            }),expression:"{\n              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,\n              resourceAction:\n                $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,\n            }"}],staticClass:"d-inline-block px-50 text-indigo",attrs:{"to":{
              name: 'editPurchaseInvoice',
              params: { id: _vm.$route.params.id },
            }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Editar","icon":"Edit2Icon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
              resource: _vm.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction:
                _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
            }),expression:"{\n              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,\n              resourceAction:\n                $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,\n            }"}]},[_c('span',{staticClass:"d-inline-block px-50 text-danger",on:{"click":function($event){return _vm.handleDeleteSaleInvoiceButtonClick()}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)])],1)],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('small',{staticClass:"text-dark font-weight-bold"},[_vm._v(" Nº Factura proveedor "+_vm._s(_vm.purchaseInvoice ? _vm.purchaseInvoice.provider_code : "")+" ")]),_c('div',{staticClass:"font-weight-bold text-capitalize text-dark mb-0"},[_vm._v(" "+_vm._s(_vm.purchaseInvoice && _vm.purchaseInvoice.provider ? _vm.purchaseInvoice.provider.name : "")+" "),_c('StatusBadge',{staticClass:"font-small-3 ml-2",attrs:{"status":_vm.purchaseInvoice ? _vm.purchaseInvoice.status.name : ''}}),(_vm.purchaseInvoice && 
                _vm.purchaseInvoice.send_payment_document && 
                _vm.purchaseInvoice.send_payment_document === 1
              )?_c('StatusBadge',{staticClass:"font-small-3 ml-1",attrs:{"status":'Envio doc. pago',"text":'Envio doc. pago',"variant":'light-success'}}):_vm._e()],1),_c('div',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.purchaseInvoice ? _vm.purchaseInvoice.provider.cif : "")+" ")])]),_c('small',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.purchaseInvoiceDate)+" ")]),_vm._v(" vence "),_c('span',[_vm._v(" "+_vm._s(_vm.purchaseInvoiceDueDate)+" ")])])])],1)],1),_c('hr',{staticClass:"m-0"}),_c('ProfilePanel',{attrs:{"profile":_vm.purchaseInvoiceProfile}}),(_vm.purchaseInvoice && _vm.purchaseInvoice.contact)?_c('ContactsPanel',{attrs:{"title":'Contacto facturación',"contacts":[_vm.purchaseInvoice.contact]}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }