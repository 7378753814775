<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row class="p-2" align-v="center" align-h="between">
        <b-col class="font-weight-bold d-flex align-items-center" cols="auto">
          <span class="text-uppercase">conceptos e importes</span>
        </b-col>
        <b-col cols="auto">
          <span
            class="text-indigo border-bottom border-indigo font-weight-bold mr-1 cursor-pointer font-small-4"
            @click="createEmptyPurchaseInvoiceLine"
          >
            + Nuevo concepto / importe
          </span>
        </b-col>
      </b-row>
      <b-table
        hover
        responsive
        :items="purchaseInvoiceLines"
        :fields="columns"
        :no-local-sorting="true"
      >
        <template #cell(product)="data">
          <ProductSelect
            v-model="data.item.product"
            @option-selected="
              updatePurchaseInvoiceLine({
                ...data.item,
                product_id: data.item.product ? data.item.product.id : '',
              })
            "
          />
        </template>
        <template #cell(description)="data">
          <b-form-input
            v-model="data.item.description"
            placeholder="Descripción"
            @change="updatePurchaseInvoiceLine(data.item)"
          />
        </template>
        <template #cell(amount)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="data.item.amount"
              placeholder="importe"
              type="number"
              @change="updatePurchaseInvoiceLine(data.item)"
            />
            <b-input-group-append is-text> € </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(units)="data">
          <b-form-input
            v-model="data.item.units"
            placeholder="Unidades"
            type="number"
            @change="updatePurchaseInvoiceLine(data.item)"
          />
        </template>
        <template #cell(tax)="data">
          <validation-observer :ref="`taxes-${data.item.id}`">
            <validation-provider
              v-slot="validationContext"
              name="impuesto"
              rules="required"
            >
              <span class="text-dark-gray font-very-small font-weight-bold">
                <TaxesSelect
                  v-model="data.item.tax"
                  :type="data.item.tax ? data.item.tax.type : null"
                  show-type-selector
                  @option-selected="updatePurchaseInvoiceLine(data.item)"
                />
              </span>
              <br />
              <small class="text-danger">
                {{ validationContext.errors.length > 0 ? 'Selecciona un impuesto para guardar' : '' }}
              </small>
            </validation-provider>
          </validation-observer>
        </template>
        <template #cell(total)="data">
          {{ data.item.total | numberToLocalString }} €
        </template>
        <template #cell(actions)="data">
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteIconClick(data.item.id)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </template>
      </b-table>
      <div class="p-1 bg-action-blue flex-column">
        <b-row>
          <b-col cols="12" class="font-weight-bold">
            Desglose de impuestos
            <hr style="border-color: white" />
          </b-col>
          <b-col cols="12" class="text-right pb-1">
            <span class="text-dark-gray font-very-small font-weight-bold"
              >TOTAL BRUTO:
            </span>
            <span
              class="text-gray-50 justify-content-end d-inline-flex total-value-label"
            >
              {{
                (purchaseInvoice ? purchaseInvoice.total_gross : 0)
                  | numberToLocalString
              }}
              €
            </span>
          </b-col>
        </b-row>
        <b-row v-for="{ taxLine } in purchaseInvoiceTaxes" :key="taxLine.id">
          <b-col cols="3" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold">
              {{ taxLine.type.toUpperCase() }}
            </span>
          </b-col>
          <b-col cols="3" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold">
              Base disponible
            </span>
          </b-col>
          <b-col cols="3" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold">
              Impuesto
            </span>
          </b-col>
          <b-col cols="3" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold">
              Total
            </span>
          </b-col>
          <b-col cols="3" class="text-right"> {{ taxLine.percent }} % </b-col>
          <b-col cols="3" class="text-right">
            {{ taxLine.total_gross | numberToLocalString }} €
          </b-col>
          <b-col cols="3" class="text-right">
            {{ taxLine.tax | numberToLocalString }} €
          </b-col>
          <b-col cols="3" class="text-right">
            {{ taxLine.total | numberToLocalString }} €
          </b-col>
        </b-row>
        <b-row align-h="around" align-v="end" no-gutters>
          <b-col cols="12" class="text-right pt-2">
            <span class="text-dark-gray font-very-small font-weight-bold">
              TOTAL A FACTURAR:
            </span>
            <span
              class="text-gray-50 justify-content-end d-inline-flex total-value-label"
            >
              {{
                (purchaseInvoice ? purchaseInvoice.total_invoice : 0)
                  | numberToLocalString
              }}
              €
            </span>
          </b-col>
          <b-col cols="12" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold"
              >RETENCIÓN:
            </span>
            <span
              class="text-gray-50 justify-content-end d-inline-flex total-value-label"
            >
              {{
                (purchaseInvoice ? purchaseInvoice.total_retention : 0)
                  | numberToLocalString
              }}
              €
            </span>
          </b-col>
          <b-col cols="12" class="text-right">
            <span class="text-dark-gray font-very-small font-weight-bold"
              >LÍQUIDO A PERCIBIR:
            </span>
            <span
              class="text-gray-50 justify-content-end d-inline-flex total-value-label"
            >
              {{
                (purchaseInvoice ? purchaseInvoice.total_liquid : 0)
                  | numberToLocalString
              }}
              €
            </span>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApiRestService from "@/api/base-api";
import PurchaseInvoicesApi from "@/api/purchase-invoices-api";
import TaxesSelect from "@/components/tax/select/TaxesSelect.vue";
import ProductSelect from "@/components/products/select/ProductSelect.vue";

export default {
  name: "PurchaseInvoicesLinesTable",
  components: {
    ProductSelect,
    TaxesSelect,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: "PRODUCTO",
          key: "product",
          thStyle: { "min-width": "150px" },
          sortable: false,
        },
        {
          label: "DESCRIPCIÓN",
          key: "description",
          sortable: false,
        },
        {
          label: "IMPORTE",
          key: "amount",
          sortable: false,
        },
        {
          label: "UNIDADES",
          key: "units",
          sortable: false,
        },
        {
          label: "IMPUESTO",
          key: "tax",
          sortable: false,
          thStyle: { "min-width": "300px" },
        },
        {
          label: "TOTAL",
          key: "total",
          sortable: false,
          thStyle: { "min-width": "100px" },
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "75px" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("purchaseInvoice", {
      purchaseInvoice: "getPurchaseInvoice",
      purchaseInvoiceLines: "getPurchaseInvoiceLines",
    }),
    purchaseInvoiceTaxes() {
      return this.purchaseInvoice?.array_taxes.map((obj) => {
        const key = Object.keys(obj)[0];
        const newObj = obj[key];
        newObj["type"] = key;
        const updatedObj = { taxLine: newObj };
        return updatedObj;
      });
    },
  },
  methods: {
    ...mapActions("purchaseInvoice", [
      "loadPurchaseInvoice",
      "setPurchaseInvoiceTax",
    ]),
    async updatePurchaseInvoiceLine(purchaseInvoiceLine) {
      if(parseFloat(purchaseInvoiceLine.amount) > 0) {
        const valid = await this.$refs[`taxes-${purchaseInvoiceLine.id}`].validate()
        
        if (!valid) {
          return
        }
      }
      
      this.loading = true;
      try {
        await ApiRestService.edit(
          purchaseInvoiceLine.id,
          {
            ...purchaseInvoiceLine,
            purchase_invoice_id: this.purchaseInvoice.id,
          },
          this.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_LINES
        );
        await this.loadPurchaseInvoice(this.purchaseInvoice?.id);
      } catch (error) {
        this.$toast.error(error.response?.data?.error);
      } finally {
        this.loading = false;
      }
    },
    updatePurchaseInvoiceTax(tax) {
      this.setPurchaseInvoiceTax(tax);
      this.updatePurchaseInvoice();
    },
    async updatePurchaseInvoice() {
      this.loading = true;

      try {
        const purchaseOrder = { ...this.purchaseInvoice };
        delete purchaseOrder.documentation;

        await PurchaseInvoicesApi.edit(this.purchaseInvoice?.id, purchaseOrder);
        await this.loadPurchaseInvoice(this.purchaseInvoice?.id);
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteIconClick(purchaseInvoiceLineId) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el concepto o importe",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.delete(
          purchaseInvoiceLineId,
          this.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_LINES
        );
        await this.loadPurchaseInvoice(this.purchaseInvoice?.id);
      } finally {
        this.loading = false;
      }
    },
    async createEmptyPurchaseInvoiceLine() {
      this.loading = true;
      try {
        await ApiRestService.create(
          { purchase_invoice_id: this.purchaseInvoice?.id },
          this.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_LINES
        );
        await this.loadPurchaseInvoice(this.purchaseInvoice?.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.total-value-label {
  width: 150px;
}
</style>
