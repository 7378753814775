import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_PURCHASE_INVOICE_PAYMENTS } from '@/shared/constants/resources'

class PurchaseInvoicePaymentsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  async getRemaining(purchaseInvoiceId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`${this.resource}/remaining/${purchaseInvoiceId}`)
        resolve(response.data.remaining)
      } catch (error) {
        console.error(error)
        reject(error)
        Vue.$toast.error('Error al obtener el recurso. Por favor, inténtelo de nuevo mas tarde.')
      }
    })
  }
}

export default new PurchaseInvoicePaymentsApi(RESOURCE_PURCHASE_INVOICE_PAYMENTS)
