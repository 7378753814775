<template>
  <div>
    <b-row
      align-h="between"
      @click="collapse = !collapse"
    >
      <b-col
        cols="auto"
        class="text-uppercase font-weight-bold d-flex align-items-center cursor-pointer"
      >
        Historial de pagos
      </b-col>
      <b-col cols="auto">
        <b-link>
          <FeatherIcon
            v-if="!collapse"
            icon="ChevronDownIcon"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
          />
        </b-link>
      </b-col>
    </b-row>
    <hr>
    <b-collapse v-model="collapse">
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-row
          v-for="paymentItem in payments"
          :key="paymentItem.key"
          align-v="center"
        >
          <b-col>
            <span class="font-weight-bold">
              {{ paymentItem.amount | numberToLocalString }} €
            </span>
            <span v-show="paymentItem.payment_date"> - {{ paymentItem.payment_date | formatDate }}</span>
          </b-col>
          <b-col cols="auto">
            <b-link class="text-indigo mr-1">
              <feather-icon
                icon="DownloadIcon"
                size="19"
                @click="handleDownloadIconClick(paymentItem)"
              />
            </b-link>
            <b-link
              class="text-danger"
              @click="handleDeleteIconClick(paymentItem)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import PurchaseInvoicePaymentsApi from '@/api/purchase-invoice-payments-api'
import ApiRestService from '@/api/base-api'
import { mapActions } from 'vuex'

export default {
  name: 'PurchaseInvoicePaymentList',
  props: {
    purchaseInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      payments: [],
      collapse: true,
    }
  },
  computed: {
    filters() {
      return this.purchaseInvoice ? { purchase_invoice_id: this.purchaseInvoice.id } : null
    },
  },
  watch: {
    purchaseInvoice: {
      deep: true,
      handler() {
        this.loadPayments()
      },
    },
  },
  methods: {
    ...mapActions('purchaseInvoice', ['loadPurchaseInvoice']),
    async loadPayments() {
      if (!this.purchaseInvoice) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.list(
          { filters: this.filters },
          this.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_PAYMENTS,
        )
        this.payments = response.data
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(purchaseInvoicePayment) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await PurchaseInvoicePaymentsApi.delete(purchaseInvoicePayment.id)
        await this.loadPurchaseInvoice(this.purchaseInvoice.id)
      } finally {
        this.loading = false
      }
    },
    async handleDownloadIconClick(paymentItem) {
      if (!paymentItem?.documentation || paymentItem.documentation.length === 0) {
        return
      }

      this.loading = true
      try {
        const file = paymentItem.documentation[0]
        await ApiRestService.downloadArchive(file.id, file.name)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
